import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_CHANGE_EMAIL,
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGIN,
  AUTH_CHANGE_REPEAT_PASSWORD,
  AUTH_REGISTER,
  AUTH_REGISTER_FAILED,
  AUTH_REGISTER_SUCCESS,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_CLEAR_INFO,
  AUTH_INFO,
  AUTH_LOADING,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED
} from './'
import { redirectAction } from './common'

import * as firebase from "firebase/app"
//
export const loginSuccessAction = (user) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    user: user
  }
}
export const loginFailedAction = (error) => {
  return {
    type: AUTH_LOGIN_FAILED,
    error: error
  }
}
export const changeEmailAction = (email) => {
  return {
    type: AUTH_CHANGE_EMAIL,
    payload: email
  }
}
export const changePasswordAction = (password) => {
  return {
    type: AUTH_CHANGE_PASSWORD,
    payload: password
  }
}
export const changeRepeatPasswordAction = (repeatPassword) => {
  return {
    type: AUTH_CHANGE_REPEAT_PASSWORD,
    payload: repeatPassword
  }
}
export const loginAction = (email, password) => {
  return {
    type: AUTH_LOGIN,
    payload: {
      email: email,
      password: password
    }
  }
}
export const registerAction = (email, password, repeatPassword) => {
  return {
    type: AUTH_REGISTER,
    payload: {
      email: email,
      password: password,
      repeatPassword: repeatPassword
    }
  }
}
export const registerSuccessAction = (user) => {
  return {
    type: AUTH_REGISTER_SUCCESS,
    user: user
  }
}
export const registerFailedAction = (error) => {
  return {
    type: AUTH_REGISTER_FAILED,
    error: error
  }
}
export const resetPasswordAction = (email) => {
  return {
    type: AUTH_RESET_PASSWORD,
    email: email,
  }
}
export const resetPasswordSuccessAction = (email) => {
  return {
    type: AUTH_RESET_PASSWORD_SUCCESS,
    email: email
  }
}
export const resetPasswordFailedAction = (error) => {
  return {
    type: AUTH_RESET_PASSWORD_FAILED,
    error: error
  }
}
export const tryToLoginAction = (email, password) => {
  return (dispatch) => {
    dispatch(loginAction(email, password))
    firebase.auth().signInWithEmailAndPassword(
      email, password
    )
      .then(function (user) {
        // dispatch this from App.js
        // no need to repeat it here        
        dispatch(redirectAction("/devices"))
      })
      .catch(function (error) {
        var errorMessage = error.message;
        console.log(errorMessage)
        dispatch(loginFailedAction(errorMessage))
      });
  }
}
export const tryToRegisterAction = (email, password, repeatPassword) => {
  return (dispatch) => {
    dispatch(registerAction(email, password, repeatPassword))
    if (password !== repeatPassword) {
      dispatch(registerFailedAction("The entered passwords do not match!"))
      return
    }
    if (password === "") {
      dispatch(registerFailedAction("Password cannot be empty!"))
      return
    }

    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(function (user) {
        dispatch(registerSuccessAction(user))        
      })
      .catch(function (error) {
        var errorMessage = error.message;
        console.log(errorMessage)
        dispatch(registerFailedAction(errorMessage))
      });
  }
}
export const tryToResetPasswordAction = (email) => {
  return (dispatch) => {
    dispatch(resetPasswordAction(email))
    firebase.auth().sendPasswordResetEmail(
      email, null
    )
      .then(function () {
        dispatch(resetPasswordSuccessAction(email))
      })
      .catch(function (error) {
        var errorMessage = error.message;
        dispatch(resetPasswordFailedAction(errorMessage))
      });
  }
}
export const clearInfo = () => {
  return {
    type: AUTH_CLEAR_INFO,
    payload: {
      error: null,
      info: null
    }
  }
}
export const setInfo = (info) => {
  return {
    type: AUTH_INFO,
    info: info
  }
}
export const setLoading = (loading) => {
  return {
    type: AUTH_LOADING,
    loading: loading
  }
}

export const logoutAction = () => {
  return {
    type: AUTH_LOGOUT
  }
}
export const logoutSuccessAction = () => {
  return {
    type: AUTH_LOGOUT_SUCCESS
  }
}
export const logoutFailedAction = (error) => {
  return {
    type: AUTH_LOGOUT_FAILED,
    error: error
  }
}
export const tryToLogoutAction = () => {
  return (dispatch) => {
    dispatch(logoutAction())
    firebase.auth().signOut()
      .then(function () {
        dispatch(redirectAction("/"))
        dispatch(logoutSuccessAction())        
      })
      .catch(function (error) {
        var errorMessage = error.message;
        dispatch(logoutFailedAction(errorMessage))
      });
  }
}
