import React from 'react'
import Auth from './auth/Auth'
import { connect } from 'react-redux'
import { loginSuccessAction, setLoading } from '../actions/auth'
import LoadingSpinner from '../component/common/Spinner'
import * as firebase from 'firebase/app';
import "firebase/auth"
import "../common/firebase-init"
import Work from './work/Work'

class App extends React.Component {

  UNSAFE_componentWillMount() {
    const { onLogin, loading, auth } = this.props
    loading(true)
    firebase.auth().onAuthStateChanged(authUser => {
      if (auth.user !== authUser) {
        onLogin(authUser)
      }
      console.log(authUser)
      loading(false)
    });
  }

  render() {
    const { auth } = this.props
    const { user, loading } = auth
    return (
      loading === true
        ? <LoadingSpinner />
        : (
          user == null
            ? <Auth />
            : <Work />
        )
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  onLogin: (user) => {
    dispatch(loginSuccessAction(user))
  },
  loading: (isLoading) => {
    dispatch(setLoading(isLoading))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(App)