import SignIn from '../../component/auth/SignIn'
import {
  tryToLoginAction,
  changeEmailAction,
  changePasswordAction
} from '../../actions/auth'
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (event) => {
    console.log(event.target)
    switch (event.target.id) {
      case "email":
        dispatch(changeEmailAction(event.target.value))
        break
      case "password":
        dispatch(changePasswordAction(event.target.value))
        break
      default:
        break
    }
  },
  onSubmit: (email, password) => event => {
    console.log(email, password)
    event.preventDefault()
    dispatch(tryToLoginAction(email, password))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)