import * as firebase from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyDfuhFfJLXb1cabnNeG_OM8xuJl2qqvXWA",
  authDomain: "icon-frame.firebaseapp.com",
  databaseURL: "https://icon-frame.firebaseio.com",
  projectId: "icon-frame",
  storageBucket: "icon-frame.appspot.com",
  messagingSenderId: "49805170115",
  appId: "1:49805170115:web:fa6d22715446ec29a35976"
};

firebase.initializeApp(firebaseConfig);
