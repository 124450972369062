import React from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class Devices extends React.Component {

  renderDevices(devices) {
    const items = []
    let index = 0
    for (const deviceId in devices) {
      index = index + 1;
      items.push(this.renderDevice(index, deviceId, devices[deviceId]))
    }
    return (
      <>
        {items}
      </>
    )
  }

  renderDevice(index, deviceId, deviceInfo) {
    return (
      <tr key={deviceId}>
        <td>{index}</td>
        <td>{deviceInfo.name}</td>
        <td>
          <Link to={`/device/${deviceId}`}>          
          {deviceId}
          </Link>
        </td>
        <td>{deviceInfo.description}</td>
        <td>{deviceInfo.lastDate}</td>
      </tr>
    )
  }

  render() {
    const { deviceInfo } = this.props
    const { devices } = deviceInfo
    return (
      <>
        <h3 className="pl-3">Devices</h3>

        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Device ID</th>
              <th>Description</th>
              <th>Last Change</th>
            </tr>
          </thead>
          <tbody>
            {this.renderDevices(devices)}
          </tbody>
        </Table>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  deviceInfo: state.deviceInfo
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Devices)