import React from 'react'
import { Image } from 'react-bootstrap'
import logo from '../../images/logo.png'

export default class Logo extends React.Component {
  render() {
    const { size, isCircle } = this.props
    return (
      <Image
        src={logo}
        style={{ width: size, height: size }}
        rounded
        roundedCircle={isCircle} />
    )
  }
}