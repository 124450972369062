import ResetPassword from '../../component/auth/ResetPassword'
import {
  tryToResetPasswordAction,
  changeEmailAction,
  clearInfo
} from '../../actions/auth'
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (event) => {
    switch (event.target.id) {
      case "email":
        dispatch(changeEmailAction(event.target.value))
        break
      default:
        break
    }
  },
  onSubmit: (email) => event => {
    event.preventDefault()
    dispatch(tryToResetPasswordAction(email))
  },
  clearInfo: () => {
    console.log("clearInfo")
    dispatch(clearInfo())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)