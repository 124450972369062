import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { CommandType } from '../../../../common/remote/commands'
import { tryRemoteCommand } from '../../../../actions/remote'

class PingCommandComponent extends React.Component {
  render() {
    const { remote, command } = this.props
    const { deviceId } = remote
    
    return (
      <Button onClick={() => command(deviceId, CommandType.ping, CommandType.ping)}>
        Ping Device
      </Button>
    )
  }
}

class ShowSettingsCommandComponent extends React.Component {
  render() {
    const { remote, command } = this.props
    const { deviceId } = remote

    return (
      <Button onClick={() => command(deviceId, CommandType.showSettings, CommandType.ping)}>
        Show Settings Screen
      </Button>
    )
  }
}

class ButtonCommandComponent extends React.Component {
  render() {
    const { remote, command, commandKey, title } = this.props
    const { deviceId } = remote

    return (
      <Button onClick={() => command(deviceId, CommandType.button, commandKey)}>
        {title}
      </Button>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  command: (deviceId, type, key) => {
    if (deviceId) {
      dispatch(
        tryRemoteCommand(deviceId, type, key, "1")
      )
    }
  }
})
const mapStateToProps = (state) => ({
  remote: state.remote
})

export const ButtonCommand = connect(mapStateToProps, mapDispatchToProps)(ButtonCommandComponent)
export const PingCommand = connect(mapStateToProps, mapDispatchToProps)(PingCommandComponent)
export const ShowSettingsCommand = connect(mapStateToProps, mapDispatchToProps)(ShowSettingsCommandComponent) 