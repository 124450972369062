import React from "react";
import Link from '../common/Link'
import { 
  Container, Button, Form, Alert
} from 'react-bootstrap'
import '../../css/auth.css';

export default class SignIn extends React.Component {

  render() {

    const { auth, onChange, onSubmit } = this.props
    const { email, password, error } = auth
    const alert = error == null 
      ? <div/>
      : <Alert variant="danger" >{error}</Alert>

    return (
      <>
      <Container className="fixed-center" style={{width: 350}}>
        <Form onSubmit = { onSubmit(email, password) }>
          <h1 className="h3 mb-3 font-weight-normal text-center">
            Please sign in
          </h1>
          {/* alarm */}
          { alert }

          <Form.Group controlId="email">
            <Form.Control
              type="email" 
              placeholder="Enter email"               
              value = { email }
              onChange = { onChange } 
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value = { password }
              onChange = { onChange } 
            />

            <div className='text-right'>
              <Link to="/reset">
                Forgot password?
              </Link>
            </div>
          </Form.Group>
          
          <Button 
            variant="primary" 
            type="submit" 
            className='btn form-control'>
            Enter
          </Button>

          <br/><br/>

          <Container className='pt-2 text-center'>
            <p>Do not have an account?&nbsp;
              <Link to="/signup">
                Sign Up
              </Link>
            </p>
            <br/>
          </Container>

        </Form>
      </Container>
      </>
    )
  }
}