import SignUp from '../../component/auth/SignUp'
import {
  tryToRegisterAction,
  changeEmailAction,
  changePasswordAction,
  changeRepeatPasswordAction
} from '../../actions/auth'
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (event) => {
    switch (event.target.id) {
      case "email":
        dispatch(changeEmailAction(event.target.value))
        break
      case "password":
        dispatch(changePasswordAction(event.target.value))
        break
      case "repeatPassword":
        dispatch(changeRepeatPasswordAction(event.target.value))
        break
      default:
        break
    }
  },
  onSubmit: (email, password, repeatPassword) => event => {
    console.log(email, password, repeatPassword)
    event.preventDefault()
    dispatch(tryToRegisterAction(email, password, repeatPassword))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)