import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  tryRemoteCommand
} from '../../../../actions/remote'

class SaveButtonComponent extends React.Component {

  render() {
    const {
      commandKey, remote, type,
      command
    } = this.props

    const { values, changes, deviceId } = remote
        
    let value = null
    if (values[commandKey]) {
      value = values[commandKey].value
    }
    const isChanged = changes[commandKey] && value

    return (
      isChanged ?
        <>
          <Button
            disabled={!isChanged}
            variant="success"
            onClick={
              () => command(deviceId, type, commandKey, value) 
            }
          >
            Save
        </Button>
        </>
        : <></>
    )
  }
}
const mapStateToProps = (state) => ({
  remote: state.remote
})

const mapDispatchToProps = (dispatch) => ({

  command: (deviceId, type, key, value) => {
    if (deviceId && value) {
      dispatch(
        tryRemoteCommand(deviceId, type, key, value)
      )
    }
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(SaveButtonComponent)