import React from 'react'
import { connect } from 'react-redux'
import {
  PingCommand,
  ShowSettingsCommand,
  ButtonCommand
} from './Commands/ButtonCommands'
import { ParamName } from '../../../common/remote/commands'
import { TextCommand, TextPasswordCommand, TextViewCommand } from './Commands/TextCommand'
import { ImageCommand } from './Commands/ImageCommand'
import { ComboCommand } from './Commands/ComboCommand'
import { RangeCommand } from './Commands/RangeCommand'
import {
  layoutXOptions,
  layoutYOptions,
  pictureFormatOptions,
  pingPongDurationOptions,
  timerOptions
} from '../../../common/remote/commands'
import BoolCommand from './Commands/BoolCommand'
class DeviceRemoteControl extends React.Component {
  render() {
    return (
      <>
        <center><h3>Remote Settings</h3></center>
        <center>
          <PingCommand />&nbsp;
        <ShowSettingsCommand />&nbsp;
        <ButtonCommand
            commandKey="startScreen"
            title="Show Start Screen" />
        </center>
        <br />

        <center><h4>Branding</h4></center>

        <ImageCommand title="INTRO IMAGE"
          commandKey={ParamName.introImage} />
        <BoolCommand title="STRETCH INTRO IMAGE"
          commandKey={ParamName.introImageStretchedOn} />
        <RangeCommand title="INTRO IMAGE ALPHA"
          commandKey={ParamName.introImageAlpha} />
        <RangeCommand title="INTRO IMAGE SCALE"
          commandKey={ParamName.introImageScale} />
        <TextCommand title="INTRO SCREEN TEXT"
          commandKey={ParamName.introCaption} />
        <br />



        <center><h4>Picture Settings</h4></center>
        <BoolCommand title="PICTURE OPTION ENABLED"
          commandKey={ParamName.isPictureEnabled} />
        <ImageCommand title="PICTURE LOGO IMAGE"
          commandKey={ParamName.pictureImage} />
        <TextCommand title="PICTURE TEXT"
          commandKey={ParamName.pictureCaption} />
        { /*
        <center><h6>Select pictures for default templates</h6></center>
        <ImageCommand title="TEMPLATE #1 IMAGE"
          commandKey={ParamName.templateImage + "0"} />
        <ImageCommand title="TEMPLATE #2 IMAGE"
          commandKey={ParamName.templateImage + "1"} />
        <ImageCommand title="TEMPLATE #3 IMAGE"
          commandKey={ParamName.templateImage + "2"} />
          */}
        <BoolCommand title="LOGO AT BOTTOM"          
          commandKey={ParamName.pictureLogoAtBottom} />
        <ComboCommand title="LOGO X ALIGNMENT"
          options={layoutXOptions}
          commandKey={ParamName.pictureImageLayoutX} />
        <ComboCommand title="LOGO Y ALIGNMENT"
          options={layoutYOptions}
          commandKey={ParamName.pictureImageLayoutY} />
        <RangeCommand title="LOGO ALPHA"
          commandKey={ParamName.pictureImageAlpha} />
        <RangeCommand title="LOGO SCALE"
          commandKey={ParamName.pictureImageScale} />
        <br />




        <center><h4>Single Picture Settings</h4></center>
        <BoolCommand title="SINGLE PICTURE OPTION ENABLED"
          commandKey={ParamName.isSinglePictureEnabled} />
        <ImageCommand title="SINGLE PICTURE LOGO IMAGE"
          commandKey={ParamName.singlePictureImage} />
        <TextCommand title="SINGLE PICTURE TEXT"
          commandKey={ParamName.singlePictureCaption} />
        <ComboCommand title="LOGO X ALIGNMENT"
          options={layoutXOptions}
          commandKey={ParamName.singlePictureImageLayoutX} />
        <ComboCommand title="LOGO Y ALIGNMENT"
          options={layoutYOptions}
          commandKey={ParamName.singlePictureImageLayoutY} />
        <RangeCommand title="LOGO ALPHA"
          commandKey={ParamName.singlePictureImageAlpha} />
        <RangeCommand title="LOGO SCALE"
          commandKey={ParamName.singlePictureImageScale} />
        <br />


        <center><h4>Video Settings</h4></center>
        <BoolCommand title="VIDEO OPTION ENABLED"
          commandKey={ParamName.isVideoEnabled} />
        <ImageCommand title="VIDEO LOGO IMAGE"
          commandKey={ParamName.videoImage} />
        <TextCommand title="VIDEO TEXT"
          commandKey={ParamName.videoCaption} />
        <ComboCommand title="LOGO X ALIGNMENT"
          options={layoutXOptions}
          commandKey={ParamName.videoImageLayoutX} />
        <ComboCommand title="LOGO Y ALIGNMENT"
          options={layoutYOptions}
          commandKey={ParamName.videoImageLayoutY} />
        <RangeCommand title="VIDEO LOGO ALPHA"
          commandKey={ParamName.videoImageAlpha} />
        <RangeCommand title="VIDEO LOGO SCALE"
          commandKey={ParamName.videoImageScale} />
        <ComboCommand title="TEXT X ALIGNMENT"
          options={layoutXOptions}
          commandKey={ParamName.videoLabelLayoutX} />
        <ComboCommand title="TEXT Y ALIGNMENT"
          options={layoutYOptions}
          commandKey={ParamName.videoLabelLayoutY} />
        <RangeCommand title="TEXT ALPHA"
          commandKey={ParamName.videoLabelAlpha} />
        <br />

        <center><h4>Event Settings</h4></center>
        <TextCommand title="EVENT NAME"
          commandKey={ParamName.eventName} />
        <BoolCommand title="USE NAME AS GALLERY"
          commandKey={ParamName.isUseEventGallery} />
        <br />
        <center><h4>Picture Quality Settings</h4></center>
        <ComboCommand title="PICTURE FORMAT"
          options={pictureFormatOptions}
          commandKey={ParamName.imageMimeType} />
        <RangeCommand title="JPEG COMPRESSION"
          commandKey={ParamName.imageJpegCompression} />
        <br />
        <center><h4>Video Filters</h4></center>
        <BoolCommand title="REVERSE FILTER"
          commandKey={ParamName.filterReverseActive} />
        <BoolCommand title="DROP FRAMES FILTER"
          commandKey={ParamName.filterDropFramesActive} />
        <TextCommand title="DROP FRAMES COUNT"
          commandKey={ParamName.filterDropFramesCount} />
        <TextCommand title="DISPLAYED FRAMES COUNT"
          commandKey={ParamName.filterDisplayedFramesCount} />
        <BoolCommand title="PING PONG FILTER"
          commandKey={ParamName.filterPingPongActive} />
        <ComboCommand title="PING PONG DURATION"
          options={pingPongDurationOptions}
          commandKey={ParamName.filterPingPongDuration} />
        <br />

        <center><h4>Email Settings</h4></center>
        <TextCommand title="EMAIL USERNAME"
          commandKey={ParamName.emailUser} />
        <TextPasswordCommand title="EMAIL PASSWORD"
          commandKey={ParamName.emailPassword} />
        <TextCommand title="SMTP HOSTNAME"
          commandKey={ParamName.emailHostName} />
        <TextCommand title="SMTP PORT"
          commandKey={ParamName.emailSmtpPort} />
        <TextCommand title="SUBJECT LINE" as="textarea"
          commandKey={ParamName.emailSubject} />
        <TextViewCommand title="EMAIL TEXT BODY"
          commandKey={ParamName.emailBody} />

        <BoolCommand title="EMAIL HTML BODY ENABLED"
          commandKey={ParamName.isUseEmailHtmlTemplate} />
        <TextViewCommand title="EMAIL HTML BODY"
          commandKey={ParamName.emailHtmlTemplateBody} />
        <center><p style={{ fontSize: 10 }}>
          {
            "to show the image inside the html template, use the following tag <img src=\"cid:iconbooth\">"
          }
        </p></center>
        <center>
          <ButtonCommand
            commandKey="testEmail"
            title="TEST EMAIL" />
        </center>
        <br />


        <center><h4>Text Messages Settings</h4></center>
        <BoolCommand title="TEXT MESSAGES ENABLED"
          commandKey={ParamName.textMessagingOn} />

        <TextCommand title="TWILIO SID"
          commandKey={ParamName.twilioAccountSID} />
        <TextCommand title="TWILIO AUTH TOKEN"
          commandKey={ParamName.twilioAuthToken} />
        <TextCommand title="PHONE NUMBER"
          commandKey={ParamName.twilioPhoneNumber} />
        <TextViewCommand title="SMS BODY"
          commandKey={ParamName.smsBodyText} />
        <br />
        <center><h4>Other Settings</h4></center>
        <ComboCommand title="COUNT DOWN TIMER"
          options={timerOptions}
          commandKey={ParamName.countDownTimer} />
        <ComboCommand title="START DELAY"
          options={timerOptions}
          commandKey={ParamName.startDelay} />
        <TextCommand title="LICENSE AGREEMENT #1"
          commandKey={ParamName.agreement1} />
        <TextCommand title="LICENSE AGREEMENT #2"
          commandKey={ParamName.agreement2} />
        <br />
        <center><h4>Information</h4></center>
        <TextCommand title="SEND EMAIL LIST TO"
          commandKey={ParamName.emailList} />
        <TextCommand title="AUTOMATICALLY SEND ON DAYS"
          commandKey={ParamName.emailScheduleDays} />
        <center><p style={{ fontSize: 10 }}>
          {
            "Example: 1,2,5,7,12"
          }
        </p></center>

      </>

    )
  }
}

const mapStateToProps = (state) => ({
  remote: state.remote
})

export default connect(mapStateToProps)(DeviceRemoteControl)