import { REDIRECT } from './'

export const redirectAction = link => {
  return {
    type: REDIRECT,
    redirect: link
  }
}

export const clearRedirect = () => {
  return {
    type: REDIRECT,
    redirect: null
  }
}