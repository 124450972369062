import React from 'react'
import { Navbar, Form, Button } from 'react-bootstrap'

export default class SavePanel extends React.Component {

  render() {
    // TODO: make fluid panel with save all button
    const { saveAll, remote } = this.props
    const { changes, deviceId, values } = remote

    let isShow = false
    
    if( changes ) {
      const filtered = Object.keys(changes)
      .filter( key => {
        return changes[key] != null
      })

      console.log(values)
      isShow = filtered.length > 0
    }

    return (
      isShow ?
      <>
        <Navbar bg="light" fixed='bottom' style={{ fontSize: 12 }}>
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Save all changes&nbsp;
            </Navbar.Text>
          </Navbar.Collapse>
          <Form>
            <Button
              className='pt-0 pb-0 m-1'
              variant="success"
              
              onClick={() => saveAll(deviceId, values)}
            >
              Save
            </Button>
          </Form>
        </Navbar>
      </>
      : <></>
    )
  }
}