import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'

import thunk from 'redux-thunk'
import rootReducer from '../reducers'

const configureStore = preloadedState => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, createLogger())
)

export default configureStore