import {
  REMOTE_COMMAND,
  REMOTE_COMMAND_SUCCESS,
  REMOTE_COMMAND_FAILED,
  REMOTE_COMMAND_CHANGE_VALUE,
  REMOTE_COMMAND_FILE_LOAD,
  REMOTE_COMMAND_FILE_LOAD_SUCCESS,
  REMOTE_COMMAND_FILE_LOADING,
  REMOTE_COMMAND_SET_CURRENT_DEVICE
} from '../actions'
import { CommandType } from '../common/remote/commands';

const defaultState = {
  deviceId: null,
  changes: [],
  values: [],
  urls: [],
  loadingProgress: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REMOTE_COMMAND:
      return state
    case REMOTE_COMMAND_SUCCESS:
      {
        const changes = state.changes
        changes[action.key] = null
        return {
          ...state, changes: changes
        }
      }
    case REMOTE_COMMAND_FAILED:
      return state
    case REMOTE_COMMAND_CHANGE_VALUE:
      {
        const values = state.values
        values[action.key] = {
          value: action.value,
          commandType: action.commandType
        }
        const changes = state.changes
        changes[action.key] = true
        return {
          ...state, values: values, changes: changes
        }
      }
    case REMOTE_COMMAND_FILE_LOAD:
      {
        const urls = state.urls
        urls[action.key] = null
        const loadingProgress = state.loadingProgress
        loadingProgress[action.key] = 0
        return {
          ...state, urls: urls, loadingProgress: loadingProgress
        }
      }
    case REMOTE_COMMAND_FILE_LOAD_SUCCESS:
      {
        const urls = state.urls
        urls[action.key] = action.url
        const loadingProgress = state.loadingProgress
        loadingProgress[action.key] = null
        const values = state.values
        values[action.key] = {
          value: action.value,
          commandType: CommandType.image
        }

        return {
          ...state, urls: urls, loadingProgress: loadingProgress, values: values
        }
      }
    case REMOTE_COMMAND_FILE_LOADING:
      const loadingProgress = state.loadingProgress
      loadingProgress[action.key] = action.progress
      return {
        ...state, loadingProgress: loadingProgress
      }
    case REMOTE_COMMAND_SET_CURRENT_DEVICE:
      return {
        ...state, deviceId: action.deviceId
      }

    default:
      return state;
  }
};