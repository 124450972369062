import { combineReducers } from 'redux'
import authReducer from './auth'
import devicesReducer from './devices'
import commonReducer from './common'
import remoteReducer from './remote'

export default combineReducers({
  auth: authReducer,
  deviceInfo: devicesReducer,
  common: commonReducer,
  remote: remoteReducer
})
