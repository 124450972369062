import * as firebase from 'firebase/app';
import 'firebase/database'
import 'firebase/storage'
import {
  REMOTE_COMMAND,
  REMOTE_COMMAND_SET_CURRENT_DEVICE,
  REMOTE_COMMAND_SUCCESS,
  REMOTE_COMMAND_CHANGE_VALUE,
  REMOTE_COMMAND_FILE_LOAD,
  REMOTE_COMMAND_FILE_LOAD_SUCCESS,
  REMOTE_COMMAND_FILE_LOADING
} from '.'
import { CommandType } from '../common/remote/commands'

export const tryRemoteCommand = (
  deviceId: string,
  commandType: CommandType,
  key: string,
  value: string
) => {
  console.log(value)
  return (dispatch: any) => {
    dispatch(remoteCommand(commandType, key, value))
    const commandsRef = firebase.database().ref()
      .child("remote")
      .child(deviceId)
      .child("commands")
      .child(key)

    if (commandsRef) {
      commandsRef.set({
        "type": commandType,
        "value": value
      }).then((newValue) => {
        console.log(newValue)
        dispatch(remoteCommandSuccess(commandType, key, value))
      })
    }
  }
}
export const tryRemoteCommands = (
  deviceId: string,
  values: any
) => {
  return (dispatch: any) => {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key].value;
        const commandType = values[key].commandType;
        dispatch (
          tryRemoteCommand(deviceId, commandType, key, value)
        )
      }
    }
  }
}

export const remoteCommand = (
  commandType: CommandType,
  key: string,
  value: string
) => ({
  type: REMOTE_COMMAND,
  commandType: commandType,
  key: key,
  value: value
})

export const remoteCommandSuccess = (
  commandType: CommandType,
  key: string,
  value: string
) => ({
  type: REMOTE_COMMAND_SUCCESS,
  commandType: commandType,
  key: key,
  value: value
})

export const remoteChangeValueAction = (
  commandType: CommandType,
  key: string,
  value: string
) => ({
  type: REMOTE_COMMAND_CHANGE_VALUE,
  key: key,
  commandType: commandType,
  value: value
})

export const tryRemoteLoadFileCommand = (
  deviceId: string,
  key: string,
  file: any
) => {
  return (dispatch: any) => {
    if (!file) {
      return
    }

    dispatch(remoteLoadFile(key))
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);
    const fileName = key + "." + ext
    const storage = firebase.storage()
    const filePath =  `${deviceId}/${fileName}`
    const fileStorageRef = storage.ref(filePath)

    const uploadTask = fileStorageRef.put(file);
    uploadTask.on("state_changed", (snapshot: any) => {
      // progress function ...
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      dispatch(
        remoteLoadingFileProgress(key, progress)
      )
      // TODO: - this.setState({ progress });
      console.log(progress);
    },
      (error: Error) => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        storage
          .ref(deviceId)
          .child(fileName)
          .getDownloadURL()
          .then((url: string) => {
            dispatch(
              remoteLoadFileSuccess(key, url)
            )

            dispatch(
              remoteChangeValueAction(CommandType.image, key, filePath)
            )
          });

      }
    )
  }
}

export const remoteLoadFile = (
  key: string
) => ({
  type: REMOTE_COMMAND_FILE_LOAD,
  key: key
})

export const remoteLoadFileSuccess = (
  key: string,
  url: any
) => ({
  type: REMOTE_COMMAND_FILE_LOAD_SUCCESS,
  key: key,
  url: url
})

export const remoteLoadingFileProgress = (
  key: string,
  progress: number
) => ({
  type: REMOTE_COMMAND_FILE_LOADING,
  key: key,
  progress: progress
})

export const remoteSetCurrentDeviceId = (
  deviceId: string
) => ({
  type: REMOTE_COMMAND_SET_CURRENT_DEVICE,
  deviceId: deviceId
})