import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App'
import { Provider } from 'react-redux'
import configureStore from './store'
import { BrowserRouter } from 'react-router-dom'
import Redirector from './component/common/Redirector'

import 'bootstrap/dist/css/bootstrap.min.css';

const store = configureStore({})

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>      
      <Redirector/>
      <App />      
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);