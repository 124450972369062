export const APP_LOAD = 'APP_LOAD';
// auth and register
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_CHANGE_EMAIL = 'AUTH_CHANGE_EMAIL';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_REPEAT_PASSWORD = 'AUTH_CHANGE_REPEAT_PASSWORD';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_FAILED = 'AUTH_REGISTER_FAILED';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILED = 'AUTH_LOGOUT_FAILED';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILED = 'AUTH_RESET_PASSWORD_FAILED';
export const AUTH_CLEAR_INFO = 'AUTH_CLEAR_INFO';
export const AUTH_INFO = 'AUTH_INFO';
export const AUTH_LOADING = 'AUTH_LOADING';
// devices
export const DEVICES_LOAD = 'DEVICES_LOAD';
export const DEVICES_LOAD_SUCCESS = 'DEVICES_LOAD_SUCCESS';
export const DEVICES_LOAD_FAILED = 'DEVICES_LOAD_FAILED';
export const DEVICE_LOAD = 'DEVICE_LOAD';
// redirect
export const REDIRECT = 'REDIRECT';
// remote
export const REMOTE_COMMAND_SET_CURRENT_DEVICE = 'REMOTE_COMMAND_SET_CURRENT_DEVICE';
export const REMOTE_COMMAND = 'REMOTE_COMMAND';
export const REMOTE_COMMAND_SUCCESS = 'REMOTE_COMMAND_SUCCESS';
export const REMOTE_COMMAND_FAILED = 'REMOTE_COMMAND_FAILED';
export const REMOTE_COMMAND_CHANGE_VALUE = 'REMOTE_COMMAND_CHANGE_VALUE';
export const REMOTE_COMMAND_FILE_LOAD = 'REMOTE_COMMAND_FILE_LOAD';
export const REMOTE_COMMAND_FILE_LOAD_SUCCESS = 'REMOTE_COMMAND_FILE_LOAD_SUCCESS';
export const REMOTE_COMMAND_FILE_LOADING = 'REMOTE_COMMAND_FILE_LOADING';


