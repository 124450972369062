import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class Redirector extends React.Component {
  render() {
    const { common } = this.props
    const { redirect } = common
    if (redirect) {
      return (
        <Redirect to={redirect}/>
      )
    }
    return <></>    
  }
}
const mapStateToProps = (state) => ({
  common: state.common
})

export default connect(
  mapStateToProps
)(Redirector)

