import React from "react";
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import ResetPassword from "./ResetPassword"
import Logo from '../../component/common/Logo'
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

class Auth extends React.Component {
  render() {
    return (      
      <Router>
        <div className="text-center">
        <Logo size="100px"/>
        </div>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/reset">
            <ResetPassword />
          </Route>
        </Switch>
      </Router>
    )
  }
}
export default Auth;