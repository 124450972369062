import React from 'react'
import Link from '../common/Link'
import { 
  Container, Button, Form, Alert
} from 'react-bootstrap'
import '../../css/auth.css';

export default class SignUp extends React.Component {
    render() {
        const { onChange, onSubmit, auth } = this.props
        const { email, password, repeatPassword, error } = auth
        const alert = error == null 
        ? <div/>
        : <Alert variant="danger" >{error}</Alert>
  
        return (
          <>
          <Container className="fixed-center" style={{width: 350}}>
            <Form onSubmit = { onSubmit(email, password, repeatPassword) }>
              <h1 className="h3 mb-3 font-weight-normal text-center">
                Sign Up
              </h1>
              {/* alarm */}
              { alert }
    
              <Form.Group controlId="email">
                <Form.Control
                  type="email" 
                  placeholder="Enter email"               
                  value = { email }
                  onChange = { onChange } 
                />
              </Form.Group>
    
              <Form.Group controlId="password">
                <Form.Control 
                  type="password" 
                  placeholder="Password" 
                  value = { password }
                  onChange = { onChange } 
                />
              </Form.Group>

              <Form.Group controlId="repeatPassword">
                <Form.Control 
                  type="password" 
                  placeholder="Repeat password" 
                  value = { repeatPassword }
                  onChange = { onChange } 
                />
              </Form.Group>
              
              <Button 
                variant="primary" 
                type="submit" 
                className='btn form-control'>
                Register new user
              </Button>
    
              <br/><br/>
    
              <Container className='pt-2 text-center'>
                <p>Already have account ? <Link to="/">Sign In</Link></p><br/>
              </Container>
    
            </Form>
          </Container>
          </>        
        )
    }    
}