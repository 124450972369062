import {
  AUTH_LOGIN,
  AUTH_CHANGE_EMAIL,
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGOUT,
  AUTH_REGISTER,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_CHANGE_REPEAT_PASSWORD,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILED,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_CLEAR_INFO,
  AUTH_INFO,
  AUTH_LOADING,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILED
} from '../actions'

const defaultState = {
  email: "",
  password: "",
  repeatPassword: "",
  user: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_CHANGE_EMAIL:
      return { ...state, email: action.payload, error: null }
    case AUTH_CHANGE_PASSWORD:
      return { ...state, password: action.payload, error: null }
    case AUTH_CHANGE_REPEAT_PASSWORD:
      return { ...state, repeatPassword: action.payload, error: null }
    case AUTH_LOGOUT:
      return { ...state, user: null };
    case AUTH_LOGIN:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        error: null
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        password: "",
        error: null,
        user: action.user
      }
    case AUTH_LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        user: null
      }
    case AUTH_REGISTER:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        repeatPassword: action.payload.repeatPassword
      };
    case AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        password: "",
        error: null,
        user: action.user
      }
    case AUTH_REGISTER_FAILED:
      return {
        ...state,
        error: action.error,
        user: null
      }
    case AUTH_RESET_PASSWORD:
      return {
        ...state,
        error: null,
        email: action.email
      }
    case AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        info: "An e-mail with instructions has been sent to e-mail " + action.email,
      }
    case AUTH_RESET_PASSWORD_FAILED:
      return {
        ...state,
        error: action.error,
      }
    case AUTH_CLEAR_INFO:
      return {
        ...state,
        info: null,
        error: null
      }
    case AUTH_INFO:
      return {
        ...state,
        info: action.info,
      }
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        user: null
      }
    case AUTH_LOGOUT_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state;
  }
};