import {
  DEVICES_LOAD,
  DEVICES_LOAD_FAILED,
  DEVICES_LOAD_SUCCESS,
  DEVICE_LOAD
} from './'
import * as firebase from 'firebase/app';
import 'firebase/database'

export const tryLoadDevicesAction = (user) => {
  return (dispatch) => {
    dispatch(loadDevicesAction())
    if (user != null) {
      const devicesRef = firebase.database().ref("users/" + user.uid + "/devices")
      devicesRef.on("value", snapshot => {
        for (const deviceId in snapshot.val()) {
          console.log(deviceId)
          let deviceRef = firebase.database().ref("devices/" + deviceId)
          deviceRef.on("value", deviceSnapShot => {
            const deviceInfo = deviceSnapShot.val()
            if (deviceInfo !== null) {
              dispatch(loadDeviceAction(deviceId, deviceSnapShot.val()))
            }
          })
        }
      });
    }
  }
}

export const loadDevicesAction = () => {
  return {
    type: DEVICES_LOAD,
    loading: true,
    devices: {}
  }
}

export const loadDeviceAction = (deviceId, deviceInfo) => {
  return {
    type: DEVICE_LOAD,
    payload: {
      deviceId: deviceId,
      deviceInfo: deviceInfo
    }
  }
}

export const loadDevicesSuccessAction = (devices) => {
  return {
    type: DEVICES_LOAD_SUCCESS,
    payload: {
      loading: false,
      devices: devices,
      error: null
    }
  }
}

export const loadDevicesFailedAction = (error) => {
  return {
    type: DEVICES_LOAD_FAILED,
    payload: {
      loading: false,
      devices: [],
      error: error
    }
  }
}