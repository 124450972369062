
export enum CommandType {
  ping = "ping",
  image = "image",
  setValue = "setValue",
  showSettings = "showSettings",
  header = "header",
  button = "button",
  setText = "setText",
  selectValue = "selectValue",
}

export const COMMAND_TYPE_PING = "ping"
export const COMMAND_TYPE_IMAGE = "image"
export const COMMAND_TYPE_SET_VALUE = "setValue"
export const COMMAND_TYPE_SHOW_SETTINGS = "showSettings"
export const COMMAND_TYPE_HEADER = "header"
export const COMMAND_TYPE_BUTTON = "button"
export const COMMAND_TYPE_SET_TEXT = "setText"
export const COMMAND_TYPE_SELECT_VALUE = "selectValue"

export enum ParamName {
  isFirstRun = "isFirstRun",
  introImage = "introImage",
  introCaption = "introCaption",
  videoImage = "videoImage",
  videoCaption = "videoCaption",
  pictureImage = "pictureImage",
  pictureCaption = "pictureCaption",
  emailUser = "emailUser",
  emailPassword = "emailPassword",
  emailSubject = "emailSubject",
  emailCopy = "emailCopy",
  countDownTimer = "countDownTimer",
  startDelay = "startDelay",
  cameraType = "cameraType",
  emailList = "emailList",
  periodicity = "periodicity",
  emailBody = "emailBody",

  emailSmtpPort = "emailSmtpPort",
  emailHostName = "emailHostName",
  emailScheduleDays = "emailScheduleDays",

  videoImageLayoutX = "videoImageLayoutX",
  videoImageLayoutY = "videoImageLayoutY",
  videoImageAlpha = "videoImageAlpha",
  videoImageScale = "videoImageScale",

  videoLabelLayoutX = "videoLabelLayoutX",
  videoLabelLayoutY = "videoLabelLayoutY",
  videoLabelAlpha = "videoLabelAlpha",

  agreement1 = "agreement1",
  agreement2 = "agreement2",
  pictureImageLayoutX = "pictureImageLayoutX",
  pictureImageLayoutY = "pictureImageLayoutY",
  pictureImageAlpha = "pictureImageAlpha",
  pictureImageScale = "pictureImageScale",
  filterPingPongActive = "filterPingPongActive",
  filterReverseActive = "filterReverseActive",
  filterDropFramesActive = "filterDropFramesActive",
  filterDropFramesCount = "filterDropFramesCount",
  filterDisplayedFramesCount = "filterDisplayedFramesCount",
  pictureLayoutIndex = "pictureLayoutIndex",

  filterPingPongDuration = "filterPingPongDuration",
  singlePictureImageLayoutX = "singlePictureImageLayoutX",
  singlePictureImageLayoutY = "singlePictureImageLayoutY",
  singlePictureImageAlpha = "singlePictureImageAlpha",
  singlePictureImageScale = "singlePictureImageScale",
  singlePictureImage = "singlePictureImage",
  singlePictureCaption = "singlePictureCaption",
  printerOn = "printerOn",
  pictureLogoAtBottom = "pictureLogoAtBottom",
  isVideoFullSize = "isVideoFullSize",

  textMessagingOn = "textMessagingOn",
  introImageStretchedOn = "introImageStretchedOn",
  introImageAlpha = "introImageAlpha",
  introImageScale = "introImageScale",

  twilioAccountSID = "twilioAccountSID",
  twilioAuthToken = "twilioAuthToken",
  twilioPhoneNumber = "twilioPhoneNumber",
  smsBodyText = "smsBodyText",

  isPictureEnabled = "isPictureEnabled",
  isSinglePictureEnabled = "isSinglePictureEnabled",
  isVideoEnabled = "isVideoEnabled",
  templateImage = "templateImage",

  isUseGoogleForEmail = "isUseGoogleForEmail",
  isUseGoogleForDrive = "isUseGoogleForDrive",

  eventName = "eventName",
  isUseEventGallery = "isUseEventGallery",
  googleDriveParentFolder = "googleDriveParentFolder",

  isUseEmailHtmlTemplate = "isUseEmailHtmlTemplate",
  emailHtmlTemplateBody = "emailHtmlTemplateBody",

  imageJpegCompression = "imageJpegCompression",
  imageMimeType = "imageMimeType",

  printerPaperWidth = "printerPaperWidth",
  printerPaperHeight = "printerPaperHeight"

}

export const layoutXOptions = { "": "", "Stretch": "STRETCH", "Left": "LEFT", "Right": "RIGHT", "Center": "CENTER" }
export const layoutYOptions = { "": "", "Stretch": "STRETCH", "Top": "TOP", "Bottom": "BOTTOM", "Center": "CENTER" }
export const boolOptions = { "": "", "Yes": "true", "No": "false" }
export const pictureFormatOptions = { "": "", "PNG": "image/png", "JPEG": "image/jpeg" }
export const pingPongDurationOptions = { "": "", "1.0": "1.0" ,"1.5": "1.5","2.0": "2.0","2.5": "2.5","3.0": "3.0" }
export const timerOptions = { "": "", "1": "1", "2": "2", "3": "3", "4": "4", "5": "5" }
