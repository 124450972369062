import React from 'react'
import { connect } from 'react-redux'
import {
  InputGroup,
  Form
} from 'react-bootstrap'
import { CommandType } from '../../../../common/remote/commands'
import { remoteChangeValueAction } from '../../../../actions/remote'
import SaveButton from './SaveButton'

class RangeCommandComponent extends React.Component {
  render() {
    const {
      title, commandKey, remote,
      onChangeValue
    } = this.props
    const { values } = remote
    let value = 1.0
    if (values[commandKey]) {
      value = values[commandKey].value
    }
    return (
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend >
            <InputGroup.Text
              style={{ width: "300px" }}
            >
              {title}
            </InputGroup.Text>
          </InputGroup.Prepend>
          &nbsp;
          <Form>
            <Form.Group controlId={commandKey}>
              <Form.Control
                type="range" max='1' min='0' step='0.01'
                className='form-control'
                custom
                onChange={onChangeValue(commandKey)}
                value={value}
              />
            </Form.Group>
          </Form>
          &nbsp; {value} &nbsp;
          <InputGroup.Append>
            <SaveButton
              commandKey={commandKey}
              type={CommandType.setValue}
            />
          </InputGroup.Append>
        </InputGroup>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  remote: state.remote
})

const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (key) => event => {
    const value = event.target.value
    dispatch(remoteChangeValueAction(CommandType.setValue, key, "" + value))
  }
})

export const RangeCommand = connect(mapStateToProps, mapDispatchToProps)(RangeCommandComponent)