import React from 'react'
import { connect } from 'react-redux'
import {
  InputGroup,
  FormControl
} from 'react-bootstrap'
import { CommandType } from '../../../../common/remote/commands'
import { remoteChangeValueAction } from '../../../../actions/remote'
import SaveButton from './SaveButton'

class ComboCommandComponent extends React.Component {
  renderOptions(options) {
    if (options) {
      const items = []
      let index = 0
      for (const option in options) {
        index = index + 1;
        items.push(this.renderOption(option, options[option]))
      }
      return (<>{items}</>)
    }
    return (<></>)
  }

  renderOption(option, value) {
    return (<option key={value} value={value}>{option}</option>)
  }

  render() {
    const {
      title, commandKey, remote, options,
      onChangeValue
    } = this.props

    const { values } = remote
    let value = ""
    if (values[commandKey]) {
      value = values[commandKey].value
    }

    return (
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend >
            <InputGroup.Text
              style={{ width: "300px" }}
            >
              {title}
            </InputGroup.Text>
          </InputGroup.Prepend>

          <FormControl
            as='select'
            onChange={onChangeValue(commandKey)}
            value={value}
            placeholder={title}
            aria-label={title}
            aria-describedby="basic-addon2"
          >
            {this.renderOptions(options)}
          </FormControl>

          <InputGroup.Append>
            <SaveButton
              commandKey={commandKey}
              type={CommandType.setValue}
            />
          </InputGroup.Append>
        </InputGroup>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  remote: state.remote
})

const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (key) => event => {
    const value = event.target.value
    if (value !== "") {
      dispatch(remoteChangeValueAction(CommandType.setValue, key, value))
    }
  }
})

export const ComboCommand = connect(mapStateToProps, mapDispatchToProps)(ComboCommandComponent)