import React from 'react'
import { connect } from 'react-redux'
import {
  InputGroup,
  ProgressBar,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { CommandType } from '../../../../common/remote/commands'
import {
  tryRemoteLoadFileCommand
} from '../../../../actions/remote'

import SaveButton from './SaveButton'


class ImageCommandComponent extends React.Component {
  render() {
    const {
      title, commandKey, remote,
      onLoadImage
    } = this.props

    const { urls, loadingProgress, deviceId } = remote
    const imageUrl = urls[commandKey]
    const progress = loadingProgress[commandKey]
    const isLoading = progress != null
      ? (<ProgressBar animated now={progress} />)
      : <></>

    return (
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text
              style={{ width: "300px" }}
            >
              {title}
            </InputGroup.Text>
          </InputGroup.Prepend>

          <OverlayTrigger
            key='top'
            placement='top'
            overlay={
              <Tooltip id="tooltip">
                Select Image to Upload
            </Tooltip>
            }
          >
            <label>
              <input
                type='file'
                accept="image/x-png,image/gif,image/jpeg"
                onChange={onLoadImage(deviceId, commandKey)}
                hidden />
              <Image rounded
                src={imageUrl}
                style={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid blue",
                  objectFit: "contain"
                }} />
              {isLoading}
            </label>
          </OverlayTrigger>

          <InputGroup.Append>
            <SaveButton
              commandKey={commandKey}
              type={CommandType.image}
            />
          </InputGroup.Append>
        </InputGroup>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  remote: state.remote
})

const mapDispatchToProps = (dispatch) => ({
  onLoadImage: (deviceId, key) => event => {
    const file = event.target.files[0];
    console.log(file)
    if (deviceId) {
      dispatch(
        tryRemoteLoadFileCommand(deviceId, key, file)
      )
    }
  }
})

export const ImageCommand = connect(mapStateToProps, mapDispatchToProps)(ImageCommandComponent)