import React from 'react'
import { ComboCommand } from './ComboCommand'
import { boolOptions } from '../../../../common/remote/commands'

export default class BoolCommand extends React.Component {
  render() {
    const { title, commandKey } = this.props

    return (
      <ComboCommand
        options={boolOptions}
        title={title}
        commandKey={commandKey}
      />
    )
  }
}