import { connect } from 'react-redux'
import { tryRemoteCommands } from '../../actions/remote'
import SavePanel from '../../component/common/SavePanel'

const mapStateToProps = (state) => ({
  remote: state.remote
})

const mapDispatchToProps = (dispatch) => ({
  saveAll: (deviceId, values) => {
    dispatch(
      tryRemoteCommands(deviceId, values)
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavePanel)