import React from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import DeviceInfo from './DeviceInfo'
import DeviceRemoteControl from './DeviceRemoteControl'
import { remoteSetCurrentDeviceId } from '../../../actions/remote'
import SavePanel from '../SavePanel'

class Device extends React.Component {
  UNSAFE_componentWillMount() {
    const { deviceId } = this.props.match.params
    const { setDeviceId } = this.props
    setDeviceId(deviceId)
  }

  render() {
    const { deviceId } = this.props.match.params
    const { devices } = this.props.deviceInfo
    const device = devices[deviceId]

    return (
      <>
        <Container>
          <DeviceInfo deviceId={deviceId} device={device} />
        </Container>
        <Container>
          <DeviceRemoteControl deviceId={deviceId} device={device} />
        </Container>
        <SavePanel/>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDeviceId: (deviceId) => {
    dispatch(
      remoteSetCurrentDeviceId(deviceId)
    )
  }
})

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceInfo
})

export default connect(mapStateToProps, mapDispatchToProps)(Device)