import React from 'react'
import {Spinner, Container} from 'react-bootstrap'

export default class LoadingSpinner extends React.Component {
  render() {
    return (
      <Container className='text-center'>
        <Spinner animation="border" role="status" className='text-center'>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    )
  }
}

