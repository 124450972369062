import React from "react";
import Link from '../common/Link'
import {
  Container, Button, Form, Alert
} from 'react-bootstrap'
import '../../css/auth.css';

export default class ResetPassword extends React.Component {

  render() {
    console.log(this.props)
    const { auth, onChange, onSubmit } = this.props
    const { email, error, info } = auth
    const alert = error == null
      ? <div />
      : <Alert variant="danger" >{error}</Alert>
    const infoAlert = info == null
      ? <div />
      : <Alert variant="info" >{info}</Alert>

    return (
      <>
        <Container className="fixed-center" style={{ width: 350 }}>
          <Form onSubmit={onSubmit(email)}>
            <h1 className="h3 mb-3 font-weight-normal text-center">
              Reset password
          </h1>
            {/* alarm or info*/}
            {alert}
            {infoAlert}

            <Form.Group controlId="email">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={onChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className='btn form-control'>
              Reset password
          </Button>

            <br /><br />

            <Container className='pt-2 text-center'>
              <p>Already have account?&nbsp;
                    <Link to="/">
                  Sign In
                    </Link>
                <br />
                Do not have an account?&nbsp;
                    <Link to="/signup">
                  Sign Up
                    </Link>
              </p>
              <br />
            </Container>

          </Form>
        </Container>
      </>
    )
  }
}