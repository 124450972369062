
import {  
  REDIRECT
} from '../actions'

const defaultState = {
  redirect: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REDIRECT:
      return {
        ...state,
        redirect: action.redirect
      }
    default:
      return state;
  }
};