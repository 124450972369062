import React from 'react'
import { Navbar, Form, Button, Nav } from 'react-bootstrap'
import Logo from './Logo'

export default class Navigation extends React.Component {

  UNSAFE_componentWillMount() {
    const { loadDevices, auth } = this.props
    const { user } = auth
    if(user.uid != null) {
      loadDevices(user)  
    }
  }

  render() {
    const { auth, logout } = this.props
    const { email } = auth.user
    return (
      <>
        <Navbar bg="light" fixed='top' style={{ fontSize: 12 }}>
          <Navbar.Brand href="/" className='m-0'>
            <Logo size="36px" />
            <Navbar.Text style={{ fontSize: 20, color: "black" }}>
              &nbsp;Icon Frame
            </Navbar.Text>
          </Navbar.Brand>          
          
          <Nav className="">
            <Nav.Link href="/devices">Devices</Nav.Link>
            <Nav.Link href="/users">Users</Nav.Link>
          </Nav>

          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Signed in as: <strong>{email}</strong>&nbsp;
            </Navbar.Text>
          </Navbar.Collapse>
          <Form>
            <Button
              className='pt-0 pb-0 m-1'
              variant="secondary"
              onClick={logout}
            >
              Exit
            </Button>
          </Form>
        </Navbar>
      </>
    )
  }
}