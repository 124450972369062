import React from 'react'
import { Table } from 'react-bootstrap'
import LoadingSpinner from '../../../component/common/Spinner'

export default class DeviceInfo extends React.Component {
  render() {
    const { deviceId, device } = this.props
    if(device) {
      return (
        <>      
          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <td>Device ID:</td>
                <td>{deviceId}</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td>{device.name}</td>
              </tr>
              <tr>
                <td>Description:</td>
                <td>{device.description}</td>
              </tr>
              <tr>
                <td>Current Screen:</td>
                <td>{device.currentScreen}</td>
              </tr>
              <tr>
                <td>Email Count:</td>
                <td>{device.emailCount}</td>
              </tr>
              <tr>
                <td>Last Email Sent Date:</td>
                <td>{device.lastEmailSentDate}</td>
              </tr>
              <tr>
                <td>Last Date:</td>
                <td>{device.lastDate}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )
    } else {
      return <LoadingSpinner/>
    }
  }
}