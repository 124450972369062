import React from 'react'
import { connect } from 'react-redux'
import {
  InputGroup,
  FormControl
} from 'react-bootstrap'
import { CommandType } from '../../../../common/remote/commands'
import { remoteChangeValueAction } from '../../../../actions/remote'
import SaveButton from './SaveButton'

class TextCommandComponent extends React.Component {
  render() {
    const {
      title, commandKey, remote, type, as,
      onChangeValue
    } = this.props
    const { values } = remote
    let value = ""
    if (values[commandKey]) {
      value = values[commandKey].value || ""
    }
    
    return (
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend >
            <InputGroup.Text
              style={{ width: "300px" }}
            >
              {title}
            </InputGroup.Text>
          </InputGroup.Prepend>

          <FormControl
            type={type}
            as={as}
            onChange={onChangeValue(commandKey)}
            value={value}
            placeholder={title}
            aria-label={title}
            aria-describedby="basic-addon2" />

          <InputGroup.Append>
            <SaveButton
              commandKey={commandKey}
              type={CommandType.setValue}
            />
          </InputGroup.Append>
        </InputGroup>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  remote: state.remote
})

const textAreaMapStateToProps = (state) => ({
  remote: state.remote,
  as: "textarea"
})

const passwordMapStateToProps = (state) => ({
  remote: state.remote,
  type: "password"
})


const mapDispatchToProps = (dispatch) => ({
  onChangeValue: (key, type) => event => {    
    const value = event.target.value
    dispatch(remoteChangeValueAction(CommandType.setValue, key, value))
  }
})

export const TextCommand = connect(mapStateToProps, mapDispatchToProps)(TextCommandComponent)
export const TextViewCommand = connect(textAreaMapStateToProps, mapDispatchToProps)(TextCommandComponent)
export const TextPasswordCommand = connect(passwordMapStateToProps, mapDispatchToProps)(TextCommandComponent)