import {
  DEVICES_LOAD,
  DEVICE_LOAD
} from '../actions'

const defaultState = {
  loading: false,
  devices: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DEVICES_LOAD:
      return {
        ...state,
        devices: action.devices
      }
      case DEVICE_LOAD:
        const newDevices = state.devices
        newDevices[action.payload.deviceId] = action.payload.deviceInfo
        return {
          ...state,
          devices: newDevices
        }
    default:
      return state;
  }
};