import { connect } from 'react-redux'
import { tryToLogoutAction } from '../../actions/auth'
import { tryLoadDevicesAction } from '../../actions/devices'
import Navigation from '../../component/common/Navigation'

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(tryToLogoutAction())
  },
  loadDevices: (user) => {
    dispatch(tryLoadDevicesAction(user))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)