import React from "react";
import {
  Redirect,
  Switch,
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import { } from 'react-bootstrap'
import Navigation from './Navigation'
import Devices from './Devices'
import Device from './Device/Device'

export default class Work extends React.Component {
  render() {
    return (
      <>
        <Router>
          <Navigation />

          <Switch>
            <Route exact path="/">
              <Redirect to="/devices" />
            </Route>

            <Route exact path="/devices">
              <Devices />
            </Route>
            <Route
              exact path="/device/:deviceId"
              component={Device}
            />

            <Route path="/reset">
            </Route>
          </Switch>
        </Router>
      </>
    )
  }
}
